import React from 'react'
import { AlertView } from '../Components/Views/Alert/AlertView'
import { controllers } from '../Controllers/Controllers'
import { MenuFormView } from '../Views/MenuView/MenuFormView'
import { MenuHouper } from '../Views/MenuView/MenuHouperView'
import { MenuItemsView } from '../Views/MenuView/MenuItemsView'

class Helpers extends React.Component {
    render(){
        const { menuHouper, menuForm } = controllers().menu
        // main navigation
        return <div className="helpers">
            <div className="helpers-box">
                <AlertView/>
                <MenuHouper 
                    ref={menuHouper.set} 
                    controller={menuHouper}
                    RightComponent={<MenuFormView ref={menuForm.set} controller={menuForm}/>}
                />
                {/* <MainLoader/> */}
            </div>
        </div>
    }
}

export { Helpers }