import { HomePageModel } from "../../../Controllers/PageControllers/HomePageModel";
import { PageView } from "../../../Core/PageView";
import { HomeLanding } from "./HomeLanding/HomeLanding";
import '../../../assets/styles/components/home.css'

class HomePageView extends PageView {

    get controller(): HomePageModel {
        return this.props.controller
    }
    render(){ 
        const { homeLanding } = this.controller
        return <div className="page home-page">
            <HomeLanding controller={homeLanding} ref={homeLanding.set} />
        </div>
    }
}

export { HomePageView }