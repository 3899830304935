import React from 'react'
// import { Route, Routes } from "react-router";
// import {BrowserRouter as Router} from 'react-router-dom'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { controllers } from '../Controllers/Controllers';
// import { controllers } from '../Controllers/Controllers'
import { navigator } from '../Controllers/Navigator'
import { HomePageView } from '../Views/ContentView/Pages/HomePageView';
import Core from './Core'
class MainNavigation extends React.Component {

    render(){
        const { home } = controllers().page
        const {page, params} = navigator()
        // @ts-ignore
        return <Router
        ref={(ref:any)=>{
            navigator().initNavigation(ref)
        }}
    >
            <Core>
                <HomePageView controller={home}/>
            </Core>
        </Router>
    }
}

export { MainNavigation }