import React from "react";
import { controllers } from "../../Controllers/Controllers";
import { OneContactInfo } from "./OneContactInfo";

class FooterView extends React.Component {

    render(){
        return <div className="footer">
        <div className="footer-box">
            <div className="contacts-box">
                <OneContactInfo 
                    title="+1(267)928-55-28"
                    link="tel:+12679285528"
                    icon="phone"
                    // imageIcon
                />
                <OneContactInfo 
                    title="event@u-stream.it"
                    link="mailto:event@u-stream.it"
                    icon="envelope"
                    // imageIcon
                />
                <OneContactInfo 
                    title="Connect"
                    onClick={()=>{
                        controllers().menu.menuHouper.show()
                    }}
                    // link="mailto:y.neidel@gmail.com"
                    // icon="mail"
                    // imageIcon
                />
            </div>
        </div>
    </div>
    }
}

export { FooterView }