// import React from 'react'
import { LogoView } from '../../Components/Views/LogoView'
// import { LButton } from '../../Components/Views/UI/LButton'
import { ViewItem } from '../../Components/Views/ViewItem'
import { MenuController } from '../../Controllers/MenuController'
import { MenuButtonView } from './MenuButtonView'

class MenuView extends ViewItem {

    get controller(): MenuController{
        return this.props.controller
    }

    render(){
        return <div className="menu">
            <div className="menu-box">
                <LogoView/>
                <div className='menu-button-area'>
                    <MenuButtonView/>
                </div>
            </div>
        </div>
    }
}

export { MenuView }