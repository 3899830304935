import { LButton } from "../../Components/Views/UI/LButton";
import { LInput } from "../../Components/Views/UI/LInput";
import { ViewItem } from "../../Components/Views/ViewItem";
import { MenuForm } from "../../Controllers/Menu/MenuForm";


class MenuFormView extends ViewItem {

    get controller():MenuForm{
        return this.props.controller
    }

    render() {
        const { onsubmitme } = this.controller
        return <div className="menu-form">
            <div className="menu-form-controller">
                <form onSubmit={onsubmitme}>
                    <LInput
                        name="username"
                        title="Enter your name"
                        type="text"
                        customStyle="input-po"
                    />
                    <LInput
                        name="email"
                        title="Enter your email or phone"
                        type="text"
                        customStyle="input-po"
                    />
                    <LButton
                        type="suit"
                        title="Join"
                        onClick={()=>{console.log('Clicked')}}
                    />
                </form>
            </div>
        </div>
    }
}

export { MenuFormView }