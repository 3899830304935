import React from "react";
import { ICONS } from "../../Constants/icons";
import { ImageView } from "./ImageView";


class LogoView extends React.Component {

    render() {
        return <div className="logo-view">
            <ImageView source={ICONS.logo} withoutLoader/>
        </div>
    }
}

export { LogoView }