export const DateParse = (serverDate: string): Date => {
    if(serverDate === undefined || serverDate === null){
        return new Date(0)
    }
    const dates = serverDate.split(' ').join('T')
    return new Date(dates)
}

const minTen = (some: number) => {
    return some < 10 ? `0${some}` : `${some}`
}

export const DateToDateString = (date: Date) => {
    return `${minTen(date.getDate())}.${minTen(date.getMonth()+1)}.${date.getFullYear()}`
}

export const DateToTimeString = (date: Date) => {
    return `${minTen(date.getHours())}:${minTen(date.getMinutes())}`
}


export const ServerDate = (serverString: string) => {
    return DateToDateString(DateParse(serverString))
}
export const ServerTime = (serverString: string) => {
    return DateToTimeString(DateParse(serverString))
}

export const dayDifferenceFromNow = (date: Date) => {
    const dateNow = new Date()
    if(date<dateNow){
        return '0'
    }
    const milisecondsDifference = date.getTime() - dateNow.getTime()
    return Math.ceil(milisecondsDifference/(1000*60*60*24))
}

export const dayDifference = (date: Date) => {
    const dateNow = new Date()
    return dateNow.getDay()===date.getDay() ? 0 : 1
}

export const timeParse = (some: string) => {
    try {
        const times = some.split(':')
        return parseInt(times[0])*60+parseInt(times[1])
    } catch(e){
        return 0
    }
}

export const hourseAndMinutesNow = () =>{
    const newDate = new Date()
    return `${newDate.getHours()}:${newDate.getMinutes()}`
}

export const HMOnTime = () =>{
    return timeParse(hourseAndMinutesNow())
}