import { Model } from "../../Components/Models/Model";


class MenuHouperModel extends Model {

    private _visible: boolean = false;

    setVisible = (bool: boolean) => {
        if(this._visible === bool){
            return
        }
        this._visible = bool
        this.updateMe()
    }

    show = () => {
        this.setVisible(true)
    }

    hide = () => {
        this.setVisible(false)
    }

    get visible(){
        return this._visible
    }
}

export { MenuHouperModel }