// @ts-ignore
import noPicture from '../assets/images/base/nopicture.png';
import logo from '../assets/images/base/ustreamlogowhite.png';
import line from '../assets/images/buttons/LineTrough.png'
import menuButton from '../assets/images/buttons/MenuButton.png'

import television from '../assets/images/page/television.png'
import mods from '../assets/images/page/mods.png'
import purpose from '../assets/images/page/purpose.png'
import fingerprint from '../assets/images/page/fingerprint.png'

export const ICONS = {
    noPicture,
    logo,
    line,
    menuButton,
}

export const LANDING = {
    television,
    mods,
    purpose,
    fingerprint
}