import { Model } from "../../Components/Models/Model";


class MenuForm extends Model {


    onsubmitme = (e:any) => {
        e.preventDefault()
        
    }

}

export { MenuForm }