import { authBody, changePass, changePasswordRequest, emailToken, forgotBody, registrationBody, tokenBody, unsubscribeBody, verifyEmail } from "../DataTypes/authResponse"
import {baseResponse} from "../DataTypes/baseResponse"
import { fetchData } from "../Helpers/FetchData"

export class UserDataProvider {

    // HOME METHODS
    static checkUserToken(body: tokenBody){
        return fetchData(
            'user/token',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }
    static getUserInfo(body: tokenBody){
        return fetchData(
            `user/getUserinfo`,
            'POST',
            {
                userToken: body.userToken
            }
        )
    }

    // AUTH STACK METHODS
    static authorization(body: authBody) {
        return fetchData(
            'user',
            'POST',
            {
                email: body.login,
                user_pass: body.password,
                remember_me: body.remember_me
            }
        )
    }
    static check(body: tokenBody) {
        return fetchData(
            'user/token',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }
    static logout(body: tokenBody) {
        return fetchData(
            'user/logout',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }

    static userInfo(body: tokenBody){
        return fetchData(
            'user/getUserInfo',
            'POST',
            {
                userToken: body.userToken,
            }
        )
    }


    // REGISTRATION

    static registration(body: registrationBody) {
        return fetchData(
            'user/register',
            'POST',
            {
                name: body.name,
                email: body.email,
                password: body.password,
                confirmpassword: body.confirmpassword,
                country: body.country,
                referal: body.referal
            }
        )
    }

    static forgotPassword(body: forgotBody){
        return fetchData(
            'reset_password',
            'POST',
            {
                email: body.email
            }
        )
    }

    static emailToken(body: emailToken){
        return fetchData(
            'check_emailhash_code',
            'POST',
            {
                emailToken: body.emailToken,
                code: body.code
            }
        )
    }

    static verifyEmail(body: verifyEmail){
        return fetchData(
            'user/verify_email',
            'POST',
            {
                verifyToken: body.verifyToken
            }
        )
    }

    static unsubscribe(body: unsubscribeBody){
        return fetchData(
            'unsubscribe',
            'POST',
            {
                unsubscribeToken: body.unsubscribeToken
            }
        )
    }

    static changePass(body: changePass){
        return fetchData(
            'changePass',
            'POST',
            {
                emailToken: body.emailToken,
                password: body.password,
                confirmpassword: body.confirmpassword
            }
        )
    }

    static changePassword(body: changePasswordRequest) {
        return fetchData(
            'user/resetpassword',
            'POST',
            {
                userToken: body.userToken,
                password: body.password,
                newpassword: body.newpassword,
                confirmpassword: body.confirmpassword
            }
        )
    }

}

export async function loadData(dataProvider: (body:any)=>any, body: {}) : Promise<baseResponse> {
    try { 
        const response:baseResponse = await dataProvider(body)
        if(response.statusCode<200){ // system error exception
            console.error("STATUS CODE", response.statusMessage)
        }
        return response
    } catch(e){
        console.log('error', e)
        throw e
    }
}