import { ImageView } from "../../../../Components/Views/ImageView";
import { ViewItem } from "../../../../Components/Views/ViewItem";
import { LANDING } from "../../../../Constants/icons";
import { DescriptionItem } from "../../Components/DescriptionItem";
import { HomeTitleComponentView } from "../../Components/HomeTitleComponent/HomeTitleComponentView";


class HomeLanding extends ViewItem {

    render(){ 
        return <div className="home-landing">
            <div className="home-landing-square">
                <div className="home-landing-square-rowbox">
                    <HomeTitleComponentView boldphrase="Get your decision" mainphrase="Streaming business with u-stream" special="Streaming it’s easy"/>
                    <div className="main-landing-image">
                        <ImageView source={LANDING.television} className="main-landing-image-ico"/>
                    </div>
                </div>
            </div>

            <div className="home-landing-square">
                <div className="home-landing-square-rowbox">
                    <HomeTitleComponentView mainphrase="What is u-stream" special="A little about us">
                        It’s special online web application tool for:<br/>
                        - OTT Providers<br/>
                        - Education Streaming Solution (Online learning/ Online courses/Coaches)<br/>
                        - Vloggers <br/>
                        - Event makers<br/>
                        - Enterprise, in company videos<br/>
                        <br/>
                        Our services including:<br/>
                        - VOD streaming control;<br/>
                        - IPTV channels streaming control;<br/>
                        - Data servers control;<br/>
                        - Auto payments system with PayPal, Stripe, Coinbase;<br/>
                        - Auto-email with templates.<br/>
                        - Auto complite users by the link<br/>
                        - Secure streaming with hidden path<br/>
                        - HTML5 Player web application<br/>
                    </HomeTitleComponentView>
                    <div className="main-landing-image">
                        <ImageView source={LANDING.mods} className="main-landing-image-ico"/>
                    </div>
                </div>
            </div>

            <div className="home-landing-square">
                <div className="home-landing-square-rowbox">
                    {/* <div className="homa-landing-full-image">
                        <ImageView source={LANDING.purpose}/>
                    </div> */}

                    <HomeTitleComponentView mainphrase="Our purpose" special="Where we going" customStyle="right-title-component">
                        <DescriptionItem 
                            title="Collaboration"
                            className="titled-desc-item"
                            customStyle="item-first"
                        >
                            Our base goal is to find new people for us to expland our community
                        </DescriptionItem> 

                        <DescriptionItem 
                            title="Expansion"
                            className="titled-desc-item"
                            customStyle="item-second"
                        >
                            We have made significant progress in developing an ideal system, and to streamline your experience, we aim to expand.
                        </DescriptionItem> 

                        <DescriptionItem 
                            title="Enlightment"
                            className="titled-desc-item"
                            customStyle="item-third"
                        >
                            Many people have stuck with old systems, but we aim to demonstrate the security and speed of the new systems.
                        </DescriptionItem> 

                    </HomeTitleComponentView>
                </div>
            </div>

            <div className="home-landing-square">
                <div className="home-landing-square-rowbox">
                    <HomeTitleComponentView mainphrase="What is our unique" special="Why we?">
                    We offer a one-stop platform for all services, <br/>
                    facilitate ongoing growth through collaboration, and
                    offer support at various stages of company <br/>
                    development. We also provide real-time problem solving.<br/><br/>
                    We have extensive market experience and are well-versed <br/>
                    in the development processes of this industry.<br/><br/>
                    We offer a fully-developed system that only requires advertising and sales efforts.
                    </HomeTitleComponentView>
                    <div className="main-landing-image">
                        <ImageView source={LANDING.fingerprint} className="main-landing-image-ico"/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export { HomeLanding }