import React from "react";
import { Fa } from "../../Components/Views/Fa";
import { ImageView } from "../../Components/Views/ImageView";

type onecontactprops = {
    link?: string; 
    onClick?: ()=>void;
    icon?: string;
    imageIcon?: string;
    title: string
}
class OneContactInfo extends React.Component {
    props: onecontactprops;
    constructor(props:onecontactprops){
        super(props)
        this.props = props
    }
    render(){ 
        const { link, title, icon, imageIcon, onClick} = this.props
        return <div className="one-contact-info">
            <a className="one-contact-link" href={link||"#"} onClick={onClick}>
                {(icon||imageIcon) && <div className="one-contact-icon">
                    {icon && <Fa icon={icon} />}
                    {imageIcon && <ImageView source={imageIcon} />}
                </div>}
                <div className="one-contact-title">
                    {title}
                </div>
            </a>
        </div>
    }
}

export { OneContactInfo }