// import { BlockList } from "./PageControllers/BlockList";
// import { Dashboard } from "./PageControllers/Dashboard"
// import { Plan } from "./PageControllers/Plan";
// import { ProxyConfig } from "./PageControllers/ProxyConfig";

import { HomePageModel } from "./PageControllers/HomePageModel";


class PageController {
    
    private readonly _home: HomePageModel

    constructor(){
        this._home = new HomePageModel('home');
    }

    get home(){
        return this._home
    }
    
}

export { PageController }