import { Model } from "../Components/Models/Model";
import { MenuForm } from "./Menu/MenuForm";
import { MenuHouperModel } from "./Menu/MenuHouperModel";
import { MenuItems } from "./Menu/MenuItems";


class MenuController extends Model {
    private _menuHouper: MenuHouperModel
    private _menuItems: MenuItems;
    private _menuForm: MenuForm;
    constructor(){
        super()
        
        this._menuHouper = new MenuHouperModel()
        this._menuItems = new MenuItems();
        this._menuForm = new MenuForm();
    }

    get menuForm(){
        return this._menuForm
    }

    get menuItems(){
        return this._menuItems
    }

    get menuHouper(){
        return this._menuHouper
    }


    page(str:string){
        this.menuItems.page(str)
    }

    removeSelected(){
        this._menuItems.removeSelected()
    }
}

export { MenuController }