import { AppSettings } from "../Constants/AppSettings"
import { baseResponse } from "../DataTypes/baseResponse"

const noInternetConnectionError:baseResponse = {
    data: [],
    status: false,
    statusCode: 0,
    statusMessage: 'The same internet connection error'
}

const duringParsingError:baseResponse = {
    data: [],
    status: false,
    statusCode: 1,
    statusMessage: 'Error during parsing json response'
}

export const fetchData = async (
    url = '',
    type = 'POST',
    body = {},
) => {
    try{
        // console.log('BODY', JSON.stringify(body))
        // const startTime = new Date().getTime();
        // console.log('sendURL', `${AppSettings.endpoint}/${url}`);
        var bodySend = {}
        if(type !== "GET"){
            // @ts-ignore
            bodySend.body = JSON.stringify(body)
        }
        const response = await fetch(
            `${AppSettings.endpoint}/${url}`,
            {
                method: type,
                headers: {
                  'Content-Type': 'text/plain',
                },
                ...bodySend
            }
        )
        // const endTime = new Date().getTime();
        // console.log('checker requestTime', endTime - startTime)
        // if(url.indexOf('get_favourites')!==-1){
        //     const text = await response.text()
        //     console.log('RESPONSE', text)
        // }
        // console.log("JSON", response);
        if (response.ok) {
            try{
                const json = await response.json();
                // console.warn("JSON2",json)
                return JSON.parse(json);
            } catch(e){
                return duringParsingError
            }
        } else {
            console.log('!OK')
            return response
        }
    } catch(e){
        console.log('error', e)
        return noInternetConnectionError
        // throw e
    }
}

