import React from "react"

type descriptionProps = {
    className?:string;
    customStyle?: string;
    title: string;
    children?:any;
}

class DescriptionItem extends React.Component {
    props: descriptionProps
    constructor(props: descriptionProps){
        super(props)
        this.props = props
    }
    render() {
        const { title, children, customStyle, className} = this.props
        return <div className={`desc-item ${className} ${customStyle}`}>
            <div className="desc-item-title">{title}</div>
            <div className="desc-item-content">{children}</div>
        </div>
    }
}

export { DescriptionItem }