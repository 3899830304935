import React from "react";
import { ImageView } from "../../Components/Views/ImageView";
import { ICONS } from "../../Constants/icons";
import { controllers } from "../../Controllers/Controllers";

class MenuButtonView extends React.Component {


    render() {
        return <div className="menu-button">
            <div className="menu-button-box" onClick={controllers().menu.menuHouper.show}>
                <ImageView source={ICONS.menuButton} withoutLoader className="menu-button-icon" tabindex={1}/>
            </div>
        </div>
    }
}

export { MenuButtonView }