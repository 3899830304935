import { HomeLandingModel } from "./HomePage/HomeLandingModel";
import { PageModel } from "./PageModel";


class HomePageModel extends PageModel {
    private _homeLanding:HomeLandingModel

    constructor(pageName: string){
        super(pageName)

        this._homeLanding = new HomeLandingModel();
    }

    get homeLanding(){
        return this._homeLanding
    }
}

export { HomePageModel }