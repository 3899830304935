import { ViewItem } from "../../Components/Views/ViewItem";
import { MenuHouperModel } from "../../Controllers/Menu/MenuHouperModel";
import './menuhouper.css'

class MenuHouper extends ViewItem {


    get controller(): MenuHouperModel {
        return this.props.controller
    }

    render() {
        const { visible, hide} = this.controller
        const { RightComponent } = this.props
        return <div className={`menu-houper menu-houper-visible-${visible}`}>
                <div className="menu-houper-close-area" onClick={hide}/>
                <div className="menu-houper-box">
                    {RightComponent}
                </div>
            </div>
    }
}

export { MenuHouper }