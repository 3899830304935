

export class AppSettings { 
    private static _baseUrl = "http://localhost:3000"
    private static _endpoint = "https://api.arkyn-tech.com/api"
    private static _testendpoint = "https://api.arkyn-tech.com/api"
    private static _sitename = "Usteam"
    private static _sitedescription = ""
    private static _devMode = true
    
    public static get endpoint(){
        return this._devMode ? this._testendpoint : this._endpoint
    }

    public static get sitename() {
        return this._sitename
    }
    
    public static get sitedescription() {
        return this._sitedescription
    }

    public static get devMode(){
        return this._devMode;
    }

    public static get baseUrl(){
        return this._baseUrl
    }
}