import { controllers } from "./Controllers";


class NavigatorImpl {
    // set main navigation function
    private _nav: any = null;
    private _navRef:any = null;
    private _beforeAuth: string = "/";
    private _page: any = ""
    private _params: any = ""

    constructor(){
        this.currentPage()
    }
    private get nav(){
        return this._nav
    }
    private set nav(value){
        this._nav = value
    }

    get page(){
        return this._page
    }
    get params(){
        return this._params
    }

    currentPage = () => {
        const {pathname} = window.location
        let pathes = pathname.split('/')
        pathes = pathes.filter(p=>p!='')
        this._page = !pathes.length ? "" : pathes[0]
        this._params = pathes.length > 1 ? pathes[1] : ""
    }

    setNavigationReference = (ref: any) => {
        this._navRef = ref
    } 
    initNavigation = (navigation: any) => {
        this.nav = navigation
    }

    private validateNav = () => {
        return this._nav && this._nav.history
    }

    private locate = (path:string) => {
        if(!this.validateNav() || this._navRef === null){
            return
        }
        this._nav.history.push(path)
        this.currentPage()
        this._navRef.forceUpdate()
    }

    navigate = (path: string) => {
        this.locate(path)
        this.afterLocateFunctions()
        // this.menuViewingControl()
    }

    redirect(link: string, target:"_blank"|"" = ""){
        window.location.href = link
    }

    afterLocateFunctions(){
        controllers().menu.menuHouper.hide()
    }
    // if screen width < 600 hide menu
    menuViewingControl(){
        // if(controllers().media.w<600){
        //     controllers().menu.hide()
        // }
    }

    savePathBeforeNavigate = () => {
        this._beforeAuth = window.location.pathname;
        // console.error("PATH SAVED", this._beforeAuth);
    }

    removeRedirectPath() {
        this._beforeAuth = '/'
    }

    navigateAuthPath = (defaultPath: string) => {
        if(this._beforeAuth !== '/'){
            // console.error('BEFORE AUTH EXIST', this._beforeAuth)
            this.navigate(this._beforeAuth);
            this._beforeAuth = ''
            return
        }
        this.navigate(defaultPath)
    }
}

// @ts-ignore
global.__app__ = global.__app__ || {};
// @ts-ignore
global.__app__.navigator =
  // @ts-ignore
  global.__app__.navigator || new NavigatorImpl();

export function navigator(): NavigatorImpl {
  // @ts-ignore
  return global.__app__.navigator;
}
