import React from 'react'
import { withRouter } from 'react-router-dom'
import { controllers } from '../Controllers/Controllers'
import { navigator } from '../Controllers/Navigator'
// import { AuthPageView } from '../Views/ContentView/Auth/AuthPage'
import { FooterView } from '../Views/FooterView/FooterView'
import { MenuView } from '../Views/MenuView/MenuView'

type corePage = {
    children: any;
    history?:any;
}

class Core extends React.Component {
    props: corePage
    constructor(props:corePage){
        super(props)
        this.props = props
    }
    render(){
        return <div className="core">
            <div className="core-box">
                <div className="menu-backblock"/>
                <MenuView controller={controllers().menu} ref={controllers().menu.set}/>
                <div className="core-content" onClick={controllers().media.mainContentClicked}>
                    <div className="core-content-box">
                        {this.props.children}
                    </div>
                </div>
                <FooterView/>
            </div>
        </div>
    }
}

export default Core;