import { Model } from "../../Components/Models/Model";
import { MenuItemModel, menuItemType } from "./MenuItemModel";

const menuItems: Array<menuItemType> = [
    {
        name: "home",
        title: 'Home',
        link: '/',
    },
    
    
]


class MenuItems extends Model {
    private _items: Array<MenuItemModel>

    constructor(){
        super()
        this._items = menuItems.map(item=>new MenuItemModel(item))
    }

    get menuItems(){
        return this._items
    }

    page(str:string){
        this.removeSelected()
        const find = this.menuItems.find(p=>p.name===str)
        if(find){
            find.setSelected(true)
        }
    }

    removeSelected(){
        const find = this._items.find(e=>e.selected)
        if(find!==undefined){
            find.setSelected(false)
        }
    }
}

export { MenuItems }