import React from "react"
import './hometitlestyle.css'
type homeTitleProps = {
    special: string;
    mainphrase: string;
    boldphrase?: string;
    children?: any;
    customStyle?: any;
}

class HomeTitleComponentView extends React.Component {
    props: homeTitleProps
    constructor(props: homeTitleProps){
        super(props)
        this.props = props
    }

    render(){ 
        const { special, mainphrase, boldphrase, children, customStyle } = this.props
        return <div className={`home-title ${customStyle}`}>
            <div className="home-title-box">
                <div className="special-phrase">
                    {special}
                </div>
                <div className="main-phrase">
                    {mainphrase}
                </div>
                <div className="bold-phrase">
                    {boldphrase}
                </div>
                {children ? <div className="text-content">
                    {children}
                </div>:null}
            </div>
        </div>
    }
    
}

export { HomeTitleComponentView }