import React from "react";
import {Provider} from 'react-redux';
import store from "./redux/redux";
import { AppStateController } from "./Core/AppStateController";
import { controllers } from "./Controllers/Controllers";
import { Helpers } from "./Core/Helpers";
import { MainNavigation } from "./Core/MainNavigation";

import './assets/styles/App.css'
import './assets/styles/media.css'
import './assets/styles/fonts.css'
import { navigator } from "./Controllers/Navigator";


class App extends React.Component {
  render() {
    return <div className="App">
            <div className="App-box horizontal-scrollbar" id="mainscroll" ref={controllers().app.mainScroll.set}>

                <Provider store={store}>
                  <AppStateController/>
                  <Helpers/>
                  <MainNavigation ref={navigator().setNavigationReference}/>
                </Provider>
            </div>
          </div>
  }
}

export default App;
